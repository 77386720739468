import * as z from 'zod';

import { CompanyId, RecurringCompanyReportId, SftpSiteId, UserId } from './BrandedIds';
import { CompanyReportFilters, CompanyReportType } from './CompanyReport';
import { HourWhole, Timezone } from './Time';
import { zodBrandedUuid } from './utils/Zod';
import { EmailString } from './validations/email';

export const RecurringCompanyReportFilters = CompanyReportFilters.partial();

export type RecurringCompanyReportFilters = z.infer<typeof RecurringCompanyReportFilters>;

export enum RecurringReportFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const RecurringCompanyReport = z.object({
  id: zodBrandedUuid<RecurringCompanyReportId>(),
  type: z.nativeEnum(CompanyReportType),
  title: z.string(),
  userId: zodBrandedUuid<UserId>(),
  companyId: zodBrandedUuid<CompanyId>(),

  emails: z.array(EmailString).optional(),
  sftpSiteId: zodBrandedUuid<SftpSiteId>().optional(),
  deliveryHour: HourWhole,
  deliveryTimeZone: Timezone,

  filters: RecurringCompanyReportFilters.optional(),
  reportingDaysDelta: z.number().int().optional(),

  rruleDeliveryDates: z.string(),
  nextReportDeliveryDate: z.date().optional(),
  nextReportToDate: z.string().optional(),
  nextReportFromDate: z.string().optional(),
  endDate: z.date().optional(),

  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecurringCompanyReport = z.infer<typeof RecurringCompanyReport>;
