import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Exact, GetDwollaCompanyQuery } from '@willow/graphql-iso/src/app';
import { DwollaBank, GetDwollaUserQuery, PortalLoanSnapshot } from '@willow/graphql-iso/src/portal';
import { AddBankAccount, BankDeleteConfirmModal, LoaderOverlay, NamedMemo } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { CompanyId } from '@willow/types-iso';

import './DwollaBankContainer.scss';

interface Props {
  banks: DwollaBank[];
  companyName: string;
  linkToken: string | null | undefined;
  microDepositVerification: boolean;
  autopaySettings: PortalLoanSnapshot['autopaySettings'];
  refetch?:
    | ((
        variables?:
          | Partial<
              Exact<{
                [key: string]: never;
              }>
            >
          | undefined,
      ) => Promise<ApolloQueryResult<GetDwollaUserQuery>>)
    | ((
        variables?:
          | Partial<
              Exact<{
                companyId: CompanyId;
              }>
            >
          | undefined,
      ) => Promise<ApolloQueryResult<GetDwollaCompanyQuery>>); // after adding account: need to refetch dwolla token so you can add another account without refreshing the page
  removeBank?: ({ variables: { bankUrl } }: { variables: { bankUrl: string } }) => Promise<any>;
  onIsBusy?: (isBusy: boolean) => void;
  onSelectedBank?: (bank: DwollaBank | undefined) => void;
}

export const DwollaBankContainer = NamedMemo<Props>(
  'DwollaBankContainer',
  ({
    banks,
    companyName,
    linkToken,
    microDepositVerification,
    autopaySettings,
    refetch,
    removeBank,
    onIsBusy,
    onSelectedBank,
  }) => {
    const [selectedBank, setBankSelected] = useState(0); // set first bank active on init
    const [isBusy, setIsBusy] = useState<boolean>(false); // disable submit button when busy
    const [deleteBankSelected, setDeleteBankSelected] = useState<DwollaBank | undefined>();
    const [showDeleteModal, setShowDeleteModal] = useState(false); // confirmation modal before deleting bank

    useEffect(() => {
      if (!linkToken) {
        toast.error('There is an issue linking new bank accounts. Please refresh the page or try again later.');
      }
    }, [linkToken]);

    const trashBank = useCallback(
      async (bank: DwollaBank) => {
        setIsBusy(true);
        if (onIsBusy) onIsBusy(true);

        try {
          if (removeBank) {
            const response = await removeBank({ variables: { bankUrl: bank.links.self } });
            if (response.data?.removeBank.errorMessage) {
              switch (response.data?.removeBank.errorMessage) {
                case 'Cannot remove bank while it has pending transfers':
                  toast.error(
                    `${bank.name} has a pending payment transaction and cannot be removed from your account until processing has completed.`,
                  );
                  break;
                default:
                  toast.error(response.data?.removeBank.errorMessage);
                  break;
              }
            } else {
              toast.success(`${bank.name} has been successfully removed.`);
            }
          }

          refetch && (await refetch());
        } catch (err) {
          toast.error('Something went wrong, please try again.');
        } finally {
          setIsBusy(false);
          if (onIsBusy) onIsBusy(false);
          setDeleteBankSelected(undefined);
        }
      },
      [refetch, removeBank, onIsBusy],
    );

    const onDeleteBankConfirm = useCallback(() => {
      setShowDeleteModal(false);

      if (!deleteBankSelected) {
        toast.error('Something went wrong! Please try again.');
        return;
      }

      trashBank(deleteBankSelected);
    }, [deleteBankSelected, trashBank]);

    const onBankSelectUpdate = useCallback(
      (index: number, bank: DwollaBank) => {
        setBankSelected(index);

        // Let parent know a new bank has been selected
        if (onSelectedBank) {
          onSelectedBank(bank);
        }
      },
      [onSelectedBank],
    );

    return (
      <div className={classnames(['bank-container position-relative'], { 'bank-container__busy': isBusy })}>
        {banks?.length > 0 && (
          <>
            <div>
              {banks.map((bank, index) => {
                return (
                  <Form.Check
                    type="radio"
                    key={`bank-${index}`}
                    id={`bank-${bank?.links.self}`}
                    className={classnames(['payment-form__radio'], {
                      'payment-form__radio_active': selectedBank === index,
                    })}
                  >
                    <Form.Check.Input
                      type="radio"
                      name="payment-bank"
                      value={bank.links.self}
                      checked={selectedBank === index}
                      onChange={() => onBankSelectUpdate(index, bank)}
                    />

                    <Form.Check.Label>
                      <div className="bank-container__label">
                        {bank.name}

                        {autopaySettings &&
                          'bankUrl' in autopaySettings &&
                          autopaySettings?.bankUrl === bank.links.self && (
                            <div className="bank-container__label__autopay">
                              <CheckOutlined />
                              Auto-Pay
                            </div>
                          )}
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          setDeleteBankSelected(bank);
                          setShowDeleteModal(true);
                        }}
                        className="bank-container__trash make-payment__container0 d-flex p-2"
                        aria-label={`Click to permanently remove your bank account for ${bank.name}`}
                      >
                        <DeleteOutlined />
                      </button>
                    </Form.Check.Label>
                  </Form.Check>
                );
              })}
            </div>
          </>
        )}

        {linkToken && (
          <AddBankAccount
            linkToken={linkToken}
            microDepositVerification={microDepositVerification}
            banks={banks}
            companyName={companyName}
            onAddBankAccount={refetch}
            onIsBusy={onIsBusy}
            setIsBusy={setIsBusy}
          />
        )}

        <LoaderOverlay isLoading={isBusy} />

        <BankDeleteConfirmModal
          showModal={showDeleteModal}
          onCancel={() => {
            setDeleteBankSelected(undefined);
            setShowDeleteModal(false);
          }}
          onConfirm={onDeleteBankConfirm}
        />
      </div>
    );
  },
);
