import { DeleteOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DeleteRepayAccountDocument, RepayAccount } from '@willow/graphql-iso/src/portal';
import { BankDeleteConfirmModal, Button } from '@willow/shared-web';
import { Form } from '@willow/shared-web/bootstrap';
import { CompanyId, LoanId } from '@willow/types-iso';

import { RepayAddAccount } from './RepayAddAccount';

interface Props {
  repayAccounts: RepayAccount[];
  onBankUpdate: (bank: RepayAccount) => void;
  companyId: CompanyId;
  loanId: LoanId;
}

export const RepayPaymentMethod = ({ repayAccounts, onBankUpdate, companyId, loanId }: Props) => {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);
  const [repayAccountToDelete, setRepayAccountToDelete] = useState<RepayAccount | undefined>(undefined);
  const [deleteRepayAccount] = useMutation(DeleteRepayAccountDocument);

  useEffect(() => {
    // Update the selected account to the last added account
    setSelectedAccountIndex(repayAccounts.length - 1);
    onBankUpdate(repayAccounts[repayAccounts.length - 1]);
  }, [repayAccounts.length, repayAccounts, onBankUpdate]);

  const onBankSelectUpdate = (index: number) => {
    setSelectedAccountIndex(index);
    onBankUpdate(repayAccounts[index]);
  };

  return (
    <div className="payment-method make-payment__container0">
      {repayAccounts.map((account, index) => {
        const isSelected = index === selectedAccountIndex;

        return (
          <Form.Check
            type="radio"
            key={`account-${index}`}
            id={`account-${account.id}`}
            className={classNames(['payment-form__radio'], {
              'payment-form__radio_active': isSelected,
            })}
          >
            <Form.Check.Input
              type="radio"
              name="payment-bank"
              value={account.payAccountId}
              checked={isSelected}
              onChange={() => onBankSelectUpdate(index)}
            />

            <Form.Check.Label>
              <div className="bank-container__label">{account.accountName}</div>
              <button
                type="button"
                onClick={() => {
                  setRepayAccountToDelete(account);
                }}
                className="bank-container__trash make-payment__container0 d-flex p-2"
                aria-label={`Click to permanently remove your bank account for ${account.accountName}`}
              >
                <DeleteOutlined />
              </button>
            </Form.Check.Label>
          </Form.Check>
        );
      })}

      {repayAccountToDelete && (
        <BankDeleteConfirmModal
          showModal={!!repayAccountToDelete}
          onCancel={() => {
            setRepayAccountToDelete(undefined);
          }}
          onConfirm={async () => {
            try {
              await deleteRepayAccount({ variables: { companyId, loanId, accountId: repayAccountToDelete.id } });
              setRepayAccountToDelete(undefined);
              toast.success('Account deleted successfully');
            } catch (error) {
              toast.error('Failed to delete account');
            }
          }}
        />
      )}

      <div className="p-3">
        {showAddAccount ? (
          <RepayAddAccount
            companyId={companyId}
            loanId={loanId}
            onClose={() => {
              setShowAddAccount(false);
            }}
          />
        ) : (
          <Button
            size="sm"
            variant="secondary"
            onClick={(e) => {
              e.preventDefault();
              setShowAddAccount(!showAddAccount);
            }}
          >
            + Add Another Account
          </Button>
        )}
      </div>
    </div>
  );
};
