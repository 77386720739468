import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { CreateRepayAccountDocument } from '@willow/graphql-iso/src/portal';
import { Button, ControlledInput, ControlledSelect, z } from '@willow/shared-web';
import { BankAccountNumber, BankRoutingNumber, CompanyId, LoanId } from '@willow/types-iso';

const RepayCreateAccountForm = z.object({
  accountType: z.enum(['checking', 'savings']),
  accountNumber: BankAccountNumber,
  routingNumber: BankRoutingNumber,
  accountName: z.string().refine((val) => val.length > 0, { message: 'Required' }),
});

export type TRepayCreateAccountForm = z.infer<typeof RepayCreateAccountForm>;

interface Props {
  companyId: CompanyId;
  loanId: LoanId;
  onClose: () => void;
}

export const RepayAddAccount = ({ companyId, loanId, onClose }: Props) => {
  const { handleSubmit, control } = useForm<TRepayCreateAccountForm>({
    resolver: zodResolver(RepayCreateAccountForm),
    defaultValues: {
      accountType: 'checking',
      accountNumber: '',
      routingNumber: '',
      accountName: '',
    },
  });

  const [createRepaymentAccount, { loading }] = useMutation(CreateRepayAccountDocument);

  const onSubmit = async (data: TRepayCreateAccountForm) => {
    try {
      await createRepaymentAccount({
        variables: {
          input: {
            accountType: data.accountType,
            accountNumber: data.accountNumber,
            routingNumber: data.routingNumber,
            name: data.accountName,
          },
          companyId,
          loanId,
        },
      });
      toast.success('Account added successfully');
      onClose();
    } catch (err: any) {
      toast.error(`Failed to add account: ${err.message}`);
    }
  };

  return (
    <div>
      <div className="mb-4">
        <h3 className="u-fs-4 mb-2">Add an additional account</h3>
        <ControlledInput
          autoFocus
          inputClassnames="u-fs-4"
          fieldName="routingNumber"
          control={control}
          label="Routing Number"
          inputType="text"
          placeholder="Enter routing number"
          required
        />
        <ControlledInput
          inputClassnames="u-fs-4"
          fieldName="accountNumber"
          control={control}
          label="Account Number"
          inputType="text"
          placeholder="Enter account number"
          required
        />
        <ControlledSelect
          fieldName="accountType"
          control={control}
          label="Account Type"
          placeholder="Select account type"
          required
          isClearable={false}
          options={[
            { value: 'checking', label: 'Checking' },
            { value: 'savings', label: 'Savings' },
          ]}
        />
        <ControlledInput
          inputClassnames="u-fs-4"
          fieldName="accountName"
          control={control}
          label="Account Name"
          inputType="text"
          placeholder="My Checking Account"
          required
        />
      </div>

      <div className="d-flex gap-2">
        <Button size="sm" type="submit" onClick={handleSubmit(onSubmit)}>
          {loading ? 'Adding Account...' : 'Add Account'}
        </Button>
        <Button size="sm" variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
